<template>
  <div
    class="cr_todo_gantt_background"
    :class="!showItemTitle ? 'cr_hide' : ''"
    :style="`height: ${totalHeight}px;`"
  >
    <!-- 왼쪽 아이템 목록 부분 보더 -->
    <div
      class="cr_todo_gantt_item_left_border"
      :class="!showItemTitle ? 'cr_hide' : ''"
    />
    <!-- 날짜별 보더 -->
    <div
      v-for="date in dateArr"
      :key="`${date.divider}_${date.dividerPostfix}`"
      class="d-flex"
    >
      <!-- 해당 div id는 스크롤 이동시 사용됨 -->
      <div
        v-for="child in date.children"
        :key="child.key"
        :id="child.key"
        :style="`width: ${child.width}px;`"
        class="cr_todo_gantt_background_date"
        :class="
          `${child.isSunday ? 'cr_todo_gantt_sunday' : ''} ${
            child.isSaturday ? 'cr_todo_gantt_saturday' : ''
          }`
        "
      >
        <!-- 오늘 날짜 표시 -->
        <div
          v-if="child.isToday && showTodayIndication"
          class="cr_todo_gantt_today"
          :style="`left: ${left}px;`"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_background {
  display: flex;
  position: absolute;
  top: 64px;
  left: 399px;
  &.cr_hide {
    left: 59px;
  }
  .cr_todo_gantt_item_left_border {
    position: sticky;
    left: 399px;
    width: 1px;
    height: 100%;
    border-left: thin solid rgba(0, 0, 0, 0.12);
    &.cr_hide {
      left: 59px;
    }
  }
  .cr_todo_gantt_background_date {
    position: relative;
    display: flex;
    height: 100%;
    border-right: thin solid rgba(0, 0, 0, 0.12);
    .cr_todo_gantt_today {
      position: absolute;
      width: 2px;
      height: 100%;
      background: var(--v-primary-base);
    }
    &.cr_todo_gantt_sunday {
      background: #f6f7fb;
    }
    &.cr_todo_gantt_saturday {
      background: #f6f7fb;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dateArr: {
      type: Array,
      default: () => []
    },
    totalHeight: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 0
    },
    showItemTitle: {
      type: Boolean,
      default: false
    },
    selectedPeriod: {
      type: String,
      default: "month"
    }
  },
  computed: {
    ...mapGetters("todoGanttSetting", ["showTodayIndication"]),
    left() {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const date = d.getDate();
      const day = d.getDay();
      const hour = d.getHours();

      switch (this.selectedPeriod) {
        case "Date":
          return hour * (this.distance / 24);
        case "Week":
          return (day || 6.8) * this.distance;
        case "Quarter":
          return (
            this.$parent.getQuarterLastDate(year, month, date - 1) *
            this.distance
          );
        // Month
        default:
          return this.distance * (date - 1);
      }
    }
  }
};
</script>
