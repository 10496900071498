import { render, staticRenderFns } from "./GanttItem.vue?vue&type=template&id=f9ff4a18&scoped=true&"
import script from "./GanttItem.vue?vue&type=script&lang=js&"
export * from "./GanttItem.vue?vue&type=script&lang=js&"
import style0 from "./GanttItem.vue?vue&type=style&index=0&id=f9ff4a18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9ff4a18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VHover})
