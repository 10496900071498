<template>
  <div
    class="cr-todo-gantt-wrapper"
    :class="`cr-todo-gantt-cursor-${dragItemComp}`"
  >
    <GanttToolbar
      ref="ganttToolbar"
      :itemObj="itemObj"
      :groupList="groupList"
      :dateArr.sync="dateArr"
      :distance.sync="distance"
      :selectedPeriod.sync="selectedPeriod"
    />
    <div
      id="todoList"
      ref="scrollEl"
      class="cr-todo-gantt"
      :class="showDetail ? 'cr-detail' : ''"
      style="overflow: scroll;"
      @scroll="scroll"
      @mousedown="mousedown"
    >
      <GanttBackground
        :dateArr="dateArr"
        :totalHeight="totalHeight"
        :distance.sync="distance"
        :showItemTitle="showItemTitle"
        :selectedPeriod="selectedPeriod"
      />
      <GanttItemList
        :dateArr="dateArr"
        :groupList="groupList"
        :itemObj="itemObj"
        :totalDateWidth="totalDateWidth"
        :totalHeight="totalHeight"
        :distance="distance"
        :dragItemId="dragItemId"
        :showItemTitle="showItemTitle"
        :selectedPeriod="selectedPeriod"
      />
      <GanttHeader
        :dateArr="dateArr"
        :totalDividerWidth="totalDividerWidth"
        :showItemTitle.sync="showItemTitle"
      />

      <Menu />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-gantt-wrapper {
  position: relative;
  height: calc(100% - 66px);

  &.cr-todo-gantt-cursor-right::v-deep,
  &.cr-todo-gantt-cursor-left::v-deep {
    cursor: e-resize !important;
    * {
      cursor: e-resize !important;
    }
  }

  &.cr-todo-gantt-cursor-move::v-deep {
    cursor: grabbing !important;
    * {
      cursor: grabbing !important;
    }

    .cr_todo_gantt_date_range_left,
    .cr_todo_gantt_date_range_right {
      display: none;
    }
    .cr_todo_gantt_date_range.cr-dragged {
      border-radius: 7px;
    }
  }

  .cr-todo-gantt {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      background: rgba(0, 0, 0, 0.04);
    }
    &.cr-detail {
      overflow: hidden;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
import genDateUtils from "./gantt/genDateUtils.js";

import Menu from "./group/Menu.vue";
import GanttToolbar from "./gantt/GanttToolbar.vue";
import GanttHeader from "./gantt/GanttHeader.vue";
import GanttBackground from "./gantt/GanttBackground.vue";
import GanttItemList from "./gantt/GanttItemList.vue";

export default {
  components: {
    Menu,
    GanttToolbar,
    GanttHeader,
    GanttBackground,
    GanttItemList
  },
  mounted() {
    this.$nextTick(() => {
      const { scrollEl: el } = this.$refs;
      el.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
      document.addEventListener("keydown", this.keydown);
      document.addEventListener("keyup", this.keyup);
      document.addEventListener("wheel", this.wheel, { passive: false });

      this.initDate(this.firstItemStartDate);
    });
  },
  destroyed() {
    document.removeEventListener("mouseup", this.mouseup);
    document.removeEventListener("keydown", this.keydown);
    document.removeEventListener("keyup", this.keyup);
    document.removeEventListener("wheel", this.wheel, { passive: false });
  },
  data() {
    const day = new Date();

    return {
      pressedCtrl: false,
      dateArr: [],
      selectedPeriod: "Month",
      thisYear: day.getFullYear(),
      thisMonth: day.getMonth() + 1,
      thisDate: day.getDate(),

      // 날짜당 간격
      distanceValue: 6,
      minDistance: 6,
      maxDistance: 26,
      // 아이템 타이틀 숨기기
      showItemTitle: true,

      // Gantt 마우스로 스크롤 이동에 사용
      dragStart: false,
      pageX: 0,
      pageY: 0,
      scrollLeft: 0,

      // 날짜 변경
      dragItemComp: "",
      dragItemId: "",
      df: "YYYY-MM-DD"
    };
  },
  computed: {
    ...mapGetters("todoGroup", ["groups"]),
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoDetail", { showDetail: "show" }),
    ...mapGetters("todoHeader", ["header", "titleHeader", "timelineHeaders"]),
    ...mapGetters("todoGanttSetting", ["showAllItems", "hideColumns"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    itemObj() {
      const itemObj = {};
      const titleValue = this.titleHeader.value;
      const headers = this.timelineHeaders.filter(
        h => !this.hideColumns[h.value]
      );

      Object.keys(this.items).forEach(groupId => {
        const emptyItems = [];
        const items = this.items[groupId];
        itemObj[groupId] = [];

        for (let i = 0; i < items.length; i += 1) {
          const item = items[i];

          for (let j = 0; j < headers.length; j += 1) {
            const itemValue = item[headers[j].value];
            const headerValue = headers[j].value;
            const basedItem = {
              id: item.id,
              boardId: item.boardId,
              groupId: item.groupId,
              itemTitle: item[titleValue],
              headerTitle: headers[j].title,
              headerValue
            };
            // 모두 보기가 아닐때
            if (!this.showAllItems && !itemValue) continue;
            // 모두 보기인데 값이 없을때
            if (!itemValue) {
              emptyItems.push(basedItem);
              continue;
            }

            // 값이 있을때만
            let [a, b] = item[headerValue]?.split(",") ?? [];
            let start = a;
            let end = b;

            if (new Date(a).getTime() > new Date(b).getTime()) {
              start = b;
              end = a;
            }

            const sd = new Date(start);
            itemObj[groupId].push({
              ...basedItem,
              start, // 시작날짜
              end, // 끝날짜
              weekNum: this.getWeekNumOfMonth(
                sd.getFullYear(),
                sd.getMonth() + 1,
                sd.getDate()
              ).at(-1),
              quarter: this.getQuarter(sd.getMonth() + 1),
              quarterLastDate: this.getQuarterLastDate(
                sd.getFullYear(),
                sd.getMonth() + 1,
                sd.getDate() - 1
              )
            });
          }
        }

        itemObj[groupId].sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        );
        itemObj[groupId] = [...itemObj[groupId], ...emptyItems];
        // 해당 하는 아이템 없을시 삭제
        if (itemObj[groupId].length === 0) {
          delete itemObj[groupId];
          return;
        }
      });

      return itemObj;
    },
    groupList() {
      const groupList = [];
      for (let i = 0; i < this.groups.length; i += 1) {
        if (!this.itemObj[this.groups[i]?.id]) continue;
        groupList.push(this.groups[i]);
      }

      return groupList;
    },
    firstItemStartDate() {
      const [firstGroup] = this.groupList;
      if (!firstGroup) return null;

      const [{ start }] = this.itemObj[firstGroup.id] || [{}];
      if (!start) return null;

      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);
      return startDate;
    },
    totalDividerWidth() {
      // 400은 아이템 타이틀 표시 영역 크기, 60은 접었을때 크기
      return (
        this.dateArr.reduce((w, { dividerWidth: c }) => w + parseFloat(c), 0) +
        (this.showItemTitle ? 400 : 60)
      );
    },
    totalDateWidth() {
      // 400은 아이템 타이틀 표시 영역 크기, 60은 접었을때 크기
      return (
        this.dateArr.reduce((w, { dateWidth: c }) => w + parseFloat(c), 0) +
        (this.showItemTitle ? 400 : 60)
      );
    },
    totalHeight() {
      if (this.groupList.length === 0) return 748;
      return this.groupList.reduce((p, c) => {
        const { length = 0 } = this.itemObj[c.id] ?? {};
        // 40: 아이템 height, 40: 그룹 타이틀 height
        return p + 40 * length + 40;
      }, 0);
    },
    distance: {
      get() {
        switch (this.selectedPeriod) {
          case "Date": {
            return this.distanceValue * 10;
          }
          case "Week": {
            return this.distanceValue * 2;
          }
          case "Quarter": {
            return parseFloat((this.distanceValue / 6).toFixed(2));
          }
          default: {
            return this.distanceValue;
          }
        }
      },
      set(zoom) {
        const distanceValue = this.distanceValue + zoom;
        if (distanceValue < this.minDistance) {
          this.distanceValue = this.minDistance;
          this.openSnackbar({ message: "최소 크기 입니다." });
          return;
        }
        if (distanceValue > this.maxDistance) {
          this.distanceValue = this.maxDistance;
          this.openSnackbar({ message: "최대 크기 입니다." });
          return;
        }
        this.distanceValue = distanceValue;
      }
    }
  },
  watch: {
    selectedPeriod() {
      document.getElementById("todoList").scrollTop = 0;
      this.initDate(this.firstItemStartDate);
    },
    distance(distance) {
      const dateArr = [...this.dateArr];
      dateArr.forEach(dateObj => {
        let dateWidth = 0;
        let dividerWidth = 0;
        dateObj.children.forEach(date => {
          switch (this.selectedPeriod) {
            case "Date":
              date.width = distance;
              dividerWidth += distance;
              break;
            case "Week":
              date.width = 7 * distance;
              dividerWidth = dateObj.lastDate * distance;
              break;
            case "Month":
              date.width = date.lastDate * distance;
              dividerWidth += date.lastDate * distance;
              break;
            case "Quarter": {
              date.width = date.lastDate * distance;
              dividerWidth += date.lastDate * distance;
              break;
            }
          }
          dateWidth += date.width;
        });

        dateObj.dateWidth = dateWidth;
        dateObj.dividerWidth = dividerWidth;
      });

      this.dateArr = dateArr;

      const { scrollEl } = this.$refs;
      const { scrollLeft: prevLeft, scrollWidth: prevWidth } = scrollEl;
      this.$nextTick(() => {
        const { scrollWidth: nextWidth } = scrollEl;
        // 스크롤 위치 유지 시키기
        scrollEl.scrollLeft = (prevLeft * nextWidth) / prevWidth;
      });
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_MSG", "HIDE_TOOLTIP"]),
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoItem", ["updateItem"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...genDateUtils,
    initDate(startDate) {
      this.dateArr = [];
      if (!startDate) startDate = new Date();

      const params = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        date: startDate.getDate()
      };

      this.genDate(params);
      this.moveScroll({ key: this.getDateKey(params) });
    },
    getDateKey({ year, month, date }) {
      switch (this.selectedPeriod) {
        case "Date": {
          return `${year}_${month}_${date}`;
        }
        case "Week": {
          const [wk, wy, wm] = this.getWeekNumOfMonth(year, month, date);
          return `${wy}_${wm}_${wk}`;
        }
        case "Quarter": {
          return `${year}_Q${this.getQuarter(month)}`;
        }
        default: {
          return `${year}_${month}`;
        }
      }
    },

    // 마우스 휠 줌인 줌아웃
    keydown(e) {
      if (e.key === "Control") {
        this.pressedCtrl = true;
      }
    },
    keyup(e) {
      if (e.key === "Control") {
        this.pressedCtrl = false;
      }
    },
    wheel(e) {
      if (this.pressedCtrl) {
        e.preventDefault();
        this.distance = (e.wheelDeltaY > 0 ? 1 : -1) * 2;
      }
    },

    scroll({ target: { scrollLeft, scrollWidth } }) {
      this.CLOSE_MENU();
      if (!this.dateArr.length) return;

      const todoList = document.getElementById("todoList");
      if (todoList.style.overflow === "hidden") return;

      // 스크롤 왼쪽 이동시 과거 날짜 추가
      if (scrollLeft <= 10) {
        todoList.style.overflow = "hidden";
        const [dateObj] = this.dateArr[0]?.children;
        if (!dateObj) return;

        this.dragStart = false;
        this.genDate(dateObj);
        this.moveScroll(dateObj);
      }

      // 스크롤 오른쪽 이동시 미래 날짜 추가
      if (scrollLeft + this.$el.offsetWidth >= scrollWidth) {
        todoList.style.overflow = "hidden";
        let dateObj = { ...(this.dateArr.at(-1)?.children?.at(-1) ?? {}) };
        if (!dateObj) return;

        this.dragStart = false;
        dateObj[this.selectedPeriod === "Quarter" ? "year" : "month"] += 1;
        this.genDate(dateObj);
        this.moveScroll(dateObj);
      }
    },

    // 기준날짜로 스크롤 이동
    moveScroll({ key }) {
      this.$nextTick(() => {
        setTimeout(() => {
          const { offsetWidth: rootWidth } = this.$root.$el;
          const todoList = document.getElementById("todoList");
          const naviWidth = rootWidth - todoList.offsetWidth;
          // gantt-background에 있는 element
          const { left } =
            document.getElementById(key)?.getBoundingClientRect() ?? {};
          // 400 은 아이템 목록 width, 여유공간 20
          todoList.scrollLeft +=
            left - 400 - naviWidth - 20 + this.sidePanelWidth;
          todoList.style.overflow = "scroll";
        }, 0);
      });
    },

    // DnD 및 스크롤이동
    mousedown(e) {
      this.pageX = e.pageX;
      this.pageY = e.pageY;
      this.scrollTop = this.$refs.scrollEl.scrollTop;
      this.scrollLeft = this.$refs.scrollEl.scrollLeft;

      const className = "cr_todo_gantt_date_range";
      if (e.target.classList.contains(`${className}_left`)) {
        this.dragItemComp = "left";
        this.dragItemId = e.target.closest(`.${className}`).id;
        return;
      }

      if (e.target.classList.contains(`${className}_right`)) {
        this.dragItemComp = "right";
        this.dragItemId = e.target.closest(`.${className}`).id;
        return;
      }

      if (e.target.classList.contains(className)) {
        this.dragItemComp = "move";
        this.dragItemId = e.target.id;
        return;
      }

      // 이건 스크롤이동
      this.dragStart = true;
    },
    mousemove(e) {
      const pageX = e.pageX - this.pageX;
      const pageY = e.pageY - this.pageY;

      if (this.dragItemId && this.dragItemComp) {
        const target = document.getElementById(this.dragItemId);
        if (!target) return;

        const { dataset, parentNode } = target;
        const {
          gid,
          position,
          left: targetLeft,
          width: targetWitdh,
          fullwidth,
          maxwidth
        } = dataset;
        const labelEl = parentNode.querySelector(".cr-todo-gantt-item-label");
        const [item] = this.itemObj[gid].filter(
          i => `item_${i.id}_${i.headerValue}` == this.dragItemId
        );
        if (!item) return;

        // 기본값들
        const startDate = new Date(`${item.start} 0:0:0`);
        const endDate = new Date(`${item.end} 0:0:0`);
        const basedLeft = parseFloat(targetLeft);
        const basedWidth = parseFloat(targetWitdh);
        const fullWidth = parseFloat(fullwidth);
        const maxWidth = parseFloat(maxwidth);
        const widthGap = fullWidth - basedWidth;
        const mathSymbol = pageX > 0 ? 1 : -1;
        let numberOfDays = Math.round(Math.abs(pageX / this.distance));
        numberOfDays *= mathSymbol;

        switch (this.dragItemComp) {
          case "left": {
            // 최소 크기는 end날짜까지
            if (basedWidth - numberOfDays * this.distance < this.distance) {
              return;
            }

            const left = basedLeft + numberOfDays * this.distance;
            const width = basedWidth - numberOfDays * this.distance;
            target.style.left = left + "px";
            target.style.width = width + "px";

            // tooltip
            startDate.setDate(
              startDate.getDate() + (left - basedLeft) / this.distance
            );
            const startTxt = getDateWithoutTime(startDate.getTime(), this.df);
            const endTxt = getDateWithoutTime(endDate.getTime(), this.df);

            target.dataset.start = startTxt;
            target.dataset.end = endTxt;
            this.SET_MSG(`${startTxt} - ${endTxt}`);
            return;
          }
          case "right": {
            // 최소 크기는 start날짜까지
            if (basedWidth + numberOfDays * this.distance < this.distance) {
              return;
            }

            const styleWidth = basedWidth + numberOfDays * this.distance;
            target.style.width = styleWidth + "px";
            if (labelEl) labelEl.style.left = basedLeft + styleWidth + "px";

            // tooltip, 날짜 계산은 fullWidth로
            const width = fullWidth + numberOfDays * this.distance;
            const ed = new Date(startDate);
            ed.setDate(startDate.getDate() - 1 + width / this.distance);
            const endTxt = getDateWithoutTime(ed.getTime(), this.df);
            const startTxt = getDateWithoutTime(startDate.getTime(), this.df);

            target.dataset.start = startTxt;
            target.dataset.end = endTxt;
            this.SET_MSG(`${startTxt} - ${endTxt}`);
            return;
          }
          case "move": {
            let styleLeft = basedLeft + numberOfDays * this.distance;
            // 렌더링된 날짜보다 앞에서 시작할때
            if (widthGap > 0 && position === "first") {
              // 렌더링된 날짜 영역 첫번째에 존재시width만 변경
              if (widthGap >= styleLeft) {
                target.style.width =
                  basedWidth + numberOfDays * this.distance + "px";
              } else {
                target.style.left = styleLeft - widthGap + "px";
              }
              // 뒷부분 넘어갈때
            } else if (widthGap > 0 && position === "default") {
              const styleWidth = basedWidth - numberOfDays * this.distance + 1;
              target.style.width =
                fullWidth >= styleWidth ? styleWidth + "px" : fullWidth + "px";

              target.style.left = styleLeft + "px";
            } else {
              // 기본
              target.style.left = styleLeft + "px";
              // 뒷부분 넘어갈때
              if (maxWidth <= styleLeft + basedWidth) {
                // console.log("width를 줄인다");
              }
            }

            if (labelEl) labelEl.style.left = styleLeft + basedWidth + "px";
            const left = basedLeft + numberOfDays * this.distance;
            startDate.setDate(
              startDate.getDate() + (left - basedLeft) / this.distance
            );
            const ed = new Date(startDate);
            ed.setDate(startDate.getDate() - 1 + fullWidth / this.distance);
            const startTxt = getDateWithoutTime(startDate.getTime(), this.df);
            const endTxt = getDateWithoutTime(ed.getTime(), this.df);

            target.dataset.start = startTxt;
            target.dataset.end = endTxt;
            this.SET_MSG(`${startTxt} - ${endTxt}`);
            return;
          }
        }
      }

      if (!this.dragStart) return;
      // 스크롤 이동
      this.$refs.scrollEl.scrollLeft = this.scrollLeft - pageX;
      this.$refs.scrollEl.scrollTop = this.scrollTop - pageY;
    },
    mouseup(e) {
      this.dragStart = false;

      if (this.dragItemId && this.dragItemComp) {
        if (!e.target.closest(".cr_todo_gantt_date_range")) {
          this.HIDE_TOOLTIP();
        }

        const target = document.getElementById(this.dragItemId);
        if (!target) return;

        const { id: itemId, gid: groupId, start, end } = target.dataset;
        const [item] = this.itemObj[groupId]?.filter(
          i => `item_${i.id}_${i.headerValue}` === this.dragItemId
        );
        if (!item) return;

        this.updateItem({
          itemId,
          groupId,
          boardId: item.boardId,
          parentId: item.parentId,
          headerValue: item.headerValue,
          prevValue: item.value,
          itemValue: `${start},${end}`
        });
      }
      this.dragItemId = "";
      this.dragItemComp = "";
    }
  }
};
</script>
