const WEEK_KOR = ["첫째주", "둘째주", "셋째주", "넷째주", "다섯째주"];
const quarterObj = {
  1: [1, 2, 3],
  2: [4, 5, 6],
  3: [7, 8, 9],
  4: [10, 11, 12]
};

export default {
  // 해당 월의 마지막 날
  getLastDate(year, month) {
    return parseInt(new Date(year, month, 0).getDate());
  },
  // date가 몇년 몇월 몇주차 인지 구하는 함수
  getWeekNumOfMonth(year, month, date) {
    // 첫째주의 기준은 목요일(4)이다.
    const THURSDAY_NUM = 4;
    // 1을 넣으면 다음달의 첫번째 날짜
    const firstDate = new Date(year, month - 1, 1);
    const firstDayOfWeek = firstDate.getDay();
    // 첫째주 목요일
    let firstThursday = 1 + THURSDAY_NUM - firstDayOfWeek;
    if (firstThursday <= 0) {
      firstThursday = firstThursday + 7;
    }

    // 월요일기준으로 계산 (월요일부터 한주의 시작)
    const untilDateOfFirstWeek = firstThursday - 7 + 3;
    const weekNum = Math.ceil((date - untilDateOfFirstWeek) / 7) - 1;

    if (weekNum < 0) {
      // 첫째주 이하일 경우 전월 마지막주 계산
      return this.getWeekNumOfMonth(
        month - 1 === 0 ? year - 1 : year,
        month - 1 === 0 ? 12 : month - 1,
        this.getLastDate(year, month - 1)
      );
    }

    // 마지막 일
    const lastDate = new Date(year, month, 0);
    const lastDayOfWeek = lastDate.getDay();
    // 마지막 일이 목요일보다 작으면 다음달의 첫번째주
    if (lastDayOfWeek < 4 && lastDate.getDate() - date < lastDayOfWeek) {
      let resYear = year;
      if (month + 1 > 12) resYear -= 1;

      // 주차, 년도, 월
      return [WEEK_KOR[0], resYear, month + 1, 0];
    }

    return [WEEK_KOR[weekNum], year, month, weekNum];
  },
  // 그 달의 첫번째 월요일, 마지막 일요일 구하는 함수
  getFirstMondayAndLastSunday(year, month) {
    // 1일의 요일
    const firstDay = new Date(year, month - 1, 1).getDay();
    // 1일이 일요일이면 월요일은 2일
    let firstMonday = firstDay === 0 ? 2 : 1;
    // 1일이 월요일이 아닐때
    if (firstDay > 1) firstMonday = 9 - firstDay;

    let startDate = `${year}-${month}-${firstMonday} 0:0:0`;
    // 첫번째주가 이전달부터 시작
    if (firstDay <= 4 && firstDay > 1) {
      firstMonday -= 7;
      const prevLastDate = new Date(year, month - 1, 0).getDate();
      startDate = `${year}-${month - 1}-${prevLastDate + firstMonday} 0:0:0`;
    }

    // 마지막날의 요일
    const lastDay = new Date(year, month, 0).getDay();
    // 마지막 일
    const lastDate = new Date(year, month, 0).getDate();
    let lastSunday = lastDay !== 0 ? lastDate - lastDay : lastDate;
    // 마지막 요일이 목요일 이후
    if (lastDay >= 4) lastSunday += lastDay;

    return [firstMonday, lastSunday, startDate];
  },
  getQuarter(month) {
    return Math.ceil(month / 3);
  },
  getQuarterLastDate(year, month, date) {
    return (
      quarterObj[this.getQuarter(month)]
        .filter(m => m < month)
        .reduce((p, m) => p + this.getLastDate(year, m), 0) + date
    );
  },
  // 오늘인지 여부
  getIsToday(year, month, date, weekKor) {
    switch (this.selectedPeriod) {
      case "Date": {
        return (
          this.thisYear === year &&
          this.thisMonth === month &&
          this.thisDate === date
        );
      }
      case "Week": {
        const [wk, wy, wm] = this.getWeekNumOfMonth(
          this.thisYear,
          this.thisMonth,
          this.thisDate
        );
        return wy === year && wm === month && wk === weekKor;
      }
      case "Quarter": {
        return (
          this.thisYear === year &&
          this.getQuarter(this.thisMonth) === this.getQuarter(month)
        );
      }
      default: {
        return this.thisYear === year && this.thisMonth === month;
      }
    }
  },
  genDate({ year, month, date }) {
    const dateArr = [];
    this[`addPrev${this.selectedPeriod}`]({ year, month, date, dateArr });
    this[`addNext${this.selectedPeriod}`]({ year, month, date, dateArr });

    this.dateArr = dateArr;
  },
  // 일 단위 날짜 생성
  addPrevDate({ year: basedYear, month: basedMonth, dateArr }) {
    // 기준 날짜 전달부터
    for (let i = basedMonth - 1; i >= basedMonth - 2; i -= 1) {
      const year = i < 1 ? basedYear - 1 : basedYear;
      const month = i < 1 ? i + 12 : i;
      const lastDate = this.getLastDate(year, month);

      for (let date = lastDate; date >= 1; date -= 1) {
        const [wk, wny, wnm] = this.getWeekNumOfMonth(year, month, date);
        const divider = `${wnm}월 ${wk}`;
        const dividerPostfix = wny;
        const width = this.distance;
        const childObj = {
          key: `${year}_${month}_${date}`,
          year,
          month,
          date,
          lastDate,
          width,
          displayDate: date,
          start: `${year}-${month}-${date}`,
          end: `${year}-${month}-${date}`,
          isToday: this.getIsToday(year, month, date),
          isSunday: new Date(year, month - 1, date).getDay() === 0,
          isSaturday: new Date(year, month - 1, date).getDay() === 6
        };

        const index = dateArr.findIndex(
          d => d.divider === divider && d.dividerPostfix === dividerPostfix
        );
        if (index === -1) {
          dateArr.unshift({
            divider,
            dividerPostfix,
            dateWidth: width,
            dividerWidth: width,
            children: [childObj]
          });
          continue;
        }
        dateArr[index].dateWidth += width;
        dateArr[index].dividerWidth += width;
        dateArr[index].children.unshift(childObj);
      }
    }
  },
  addNextDate({ year: basedYear, month: basedMonth, dateArr }) {
    // 기준 달부터
    for (let i = basedMonth; i <= basedMonth + 2; i += 1) {
      const year = i > 12 ? basedYear + 1 : basedYear;
      const month = i > 12 ? i - 12 : i;
      const lastDate = this.getLastDate(year, month);

      for (let date = 1; date <= lastDate; date += 1) {
        const [wk, wny, wnm] = this.getWeekNumOfMonth(year, month, date);
        const divider = `${wnm}월 ${wk}`;
        const dividerPostfix = wny;
        const width = this.distance;
        const childObj = {
          key: `${year}_${month}_${date}`,
          year,
          month,
          date,
          lastDate,
          width,
          displayDate: date,
          start: `${year}-${month}-${date}`,
          end: `${year}-${month}-${date}`,
          isToday: this.getIsToday(year, month, date),
          isSunday: new Date(year, month - 1, date).getDay() === 0,
          isSaturday: new Date(year, month - 1, date).getDay() === 6
        };

        const index = dateArr.findIndex(
          d => d.divider === divider && d.dividerPostfix === dividerPostfix
        );
        if (index === -1) {
          dateArr.push({
            divider,
            dividerPostfix,
            dateWidth: width,
            dividerWidth: width,
            children: [childObj]
          });
          continue;
        }
        dateArr[index].dateWidth += width;
        dateArr[index].dividerWidth += width;
        dateArr[index].children.push(childObj);
      }
    }
  },
  // 주 단위 날짜 생성
  addPrevWeek({ year: basedYear, month: basedMonth, dateArr }) {
    // 기준 날짜 전달부터
    for (let i = basedMonth - 1; i >= basedMonth - 8; i -= 1) {
      const year = i < 1 ? basedYear - 1 : basedYear;
      const month = i < 1 ? i + 12 : i;
      const lastDate = this.getLastDate(year, month);
      const [
        firstMonday,
        lastSunday,
        startFullDate
      ] = this.getFirstMondayAndLastSunday(year, month);

      let n = 0;
      for (let j = firstMonday; j <= lastSunday; j += 7) {
        const d = new Date(startFullDate);
        d.setDate(d.getDate() + n * 7);
        const sfd = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        const d2 = new Date(sfd);
        d2.setDate(d2.getDate() + 6);
        const efd = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`;

        const width = 7 * this.distance;
        const divider = `${month}월`;
        const dividerPostfix = year;
        const childObj = {
          key: `${year}_${month}_${WEEK_KOR[n]}`,
          year,
          month,
          width,
          weekNum: n,
          start: sfd,
          end: efd,
          displayDate: WEEK_KOR[n],
          isToday: this.getIsToday(year, month, null, WEEK_KOR[n])
        };

        const index = dateArr.findIndex(
          d => d.divider === divider && d.dividerPostfix === dividerPostfix
        );
        // 값이 없을때
        if (index === -1) {
          let lastDate2 = lastDate;
          if (i === basedMonth - 8) {
            // ex) 22년 4월
            // 첫번째 월요일이 1일이 아닐때
            if (firstMonday > 1) {
              lastDate2 = lastDate - (firstMonday - 1);
            }

            // ex) 22년 6월
            // 여기서 이전달에서 첫번째 주에 해당하는 만큼 width 넓혀줘야함
            if (firstMonday <= 0) {
              lastDate2 = lastDate - (firstMonday - 1);
            }
          }

          dateArr.unshift({
            divider,
            dividerPostfix,
            lastDate: lastDate2,
            dateWidth: width,
            dividerWidth: lastDate2 * this.distance,
            children: [childObj]
          });
          n++;
          continue;
        }

        dateArr[index].dateWidth += width;
        dateArr[index].children.push(childObj);
        n++;
      }
    }
  },
  addNextWeek({ year: basedYear, month: basedMonth, dateArr }) {
    // 주 단위 next에서는 기준달부터
    for (let i = basedMonth; i < basedMonth + 8; i += 1) {
      const year = i > 12 ? basedYear + 1 : basedYear;
      const month = i > 12 ? i - 12 : i;
      const lastDate = this.getLastDate(year, month);
      const [
        firstMonday,
        lastSunday,
        startFullDate
      ] = this.getFirstMondayAndLastSunday(year, month);

      let n = 0;
      for (let j = firstMonday; j <= lastSunday; j += 7) {
        const d = new Date(startFullDate);
        d.setDate(d.getDate() + n * 7);
        const sfd = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        const d2 = new Date(sfd);
        d2.setDate(d2.getDate() + 6);
        const efd = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`;

        const width = 7 * this.distance;
        const divider = `${month}월`;
        const dividerPostfix = year;
        const childObj = {
          key: `${year}_${month}_${WEEK_KOR[n]}`,
          year,
          month,
          width,
          weekNum: n,
          start: sfd,
          end: efd,
          displayDate: WEEK_KOR[n],
          isToday: this.getIsToday(year, month, null, WEEK_KOR[n])
        };

        const index = dateArr.findIndex(
          d => d.divider === divider && d.dividerPostfix === dividerPostfix
        );
        // 값이 없을때
        if (index === -1) {
          dateArr.push({
            divider,
            dividerPostfix,
            lastDate,
            dateWidth: width,
            dividerWidth: lastDate * this.distance,
            children: [childObj]
          });
          n++;
          continue;
        }

        dateArr[index].dateWidth += width;
        dateArr[index].children.push(childObj);
        n++;
      }
    }
  },
  // 월 단위 날짜 생성
  addPrevMonth({ year: basedYear, month: basedMonth, dateArr }) {
    // 월 단위 prev에서는 기준달의 전달부터
    for (let i = basedMonth - 1; i >= basedMonth - 20; i -= 1) {
      let year = i < 1 ? basedYear - 1 : basedYear;
      let month = i < 1 ? i + 12 : i;
      if (month < 1) {
        year -= 1;
        month += 12;
      }

      const lastDate = this.getLastDate(year, month);
      const width = lastDate * this.distance;
      const divider = `Q${this.getQuarter(month)}`;
      const childObj = {
        key: `${year}_${month}`,
        year,
        month,
        lastDate,
        width,
        displayDate: month + "  월",
        start: `${year}-${month}-${1}`,
        end: `${year}-${month}-${lastDate}`,
        isToday: this.getIsToday(year, month)
      };

      const index = dateArr.findIndex(
        d => d.divider === divider && d.dividerPostfix === year
      );
      // 값이 없을때
      if (index === -1) {
        dateArr.unshift({
          divider,
          dividerPostfix: year,
          dateWidth: width,
          dividerWidth: width,
          children: [childObj]
        });
        continue;
      }

      dateArr[index].dateWidth += width;
      dateArr[index].dividerWidth += width;
      dateArr[index].children.unshift(childObj);
    }
  },
  addNextMonth({ year: basedYear, month: basedMonth, dateArr }) {
    // 월단위 next에서는 기준달부터
    for (let i = basedMonth; i < basedMonth + 20; i += 1) {
      let year = i > 12 ? basedYear + 1 : basedYear;
      let month = i > 12 ? i - 12 : i;
      if (month > 12) {
        year += 1;
        month -= 12;
      }
      const lastDate = new Date(year, month, 0).getDate();
      const width = lastDate * this.distance;
      const divider = `Q${this.getQuarter(month)}`;
      const childObj = {
        key: `${year}_${month}`,
        year,
        month,
        lastDate,
        width,
        displayDate: month + "  월",
        start: `${year}-${month}-${1}`,
        end: `${year}-${month}-${lastDate}`,
        isToday: this.getIsToday(year, month)
      };

      const index = dateArr.findIndex(
        d => d.divider === divider && d.dividerPostfix === year
      );
      // 값이 없을때
      if (index === -1) {
        dateArr.push({
          divider,
          dividerPostfix: year,
          dateWidth: width,
          dividerWidth: width,
          children: [childObj]
        });
        continue;
      }

      dateArr[index].dateWidth += width;
      dateArr[index].dividerWidth += width;
      dateArr[index].children.push(childObj);
    }
  },
  // 분기 단위 날짜 생성
  addPrevQuarter({ year: basedYear, dateArr }) {
    // 분기 단위 prev에서는 기준 연도의 전년도 부터
    for (let year = basedYear - 1; year >= basedYear - 10; year -= 1) {
      for (let quarter = 1; quarter <= 4; quarter += 1) {
        const lastMonth = quarter * 3;
        const lastDate = this.getLastDate(year, lastMonth);
        const qLastDate = this.getQuarterLastDate(year, lastMonth, lastDate);
        const divider = year;
        const width = qLastDate * this.distance;
        const childObj = {
          key: `${year}_Q${quarter}`,
          year,
          quarter,
          lastDate: qLastDate,
          width,
          displayDate: "Q" + quarter,
          start: `${year}-${quarterObj[quarter][0]}-1`,
          end: `${year}-${quarterObj[quarter][2]}-${lastDate}`,
          isToday: this.getIsToday(year, lastMonth)
        };

        const index = dateArr.findIndex(d => d.divider === divider);
        // 값이 없을때
        if (index === -1) {
          dateArr.unshift({
            divider,
            dividerPostfix: "",
            dateWidth: width,
            dividerWidth: width,
            children: [childObj]
          });
          continue;
        }

        dateArr[index].dateWidth += width;
        dateArr[index].dividerWidth += width;
        dateArr[index].children.push(childObj);
      }
    }
  },
  addNextQuarter({ year: basedYear, dateArr }) {
    // 분기 단위 next에서는 기준 연도 부터
    for (let year = basedYear; year < basedYear + 10; year += 1) {
      for (let quarter = 1; quarter <= 4; quarter += 1) {
        const lastMonth = quarter * 3;
        const lastDate = this.getLastDate(year, lastMonth);
        const qLastDate = this.getQuarterLastDate(year, lastMonth, lastDate);
        const divider = year;
        const width = qLastDate * this.distance;
        const childObj = {
          key: `${year}_Q${quarter}`,
          year,
          quarter,
          lastDate: qLastDate,
          width,
          displayDate: "Q" + quarter,
          start: `${year}-${quarterObj[quarter][0]}-1`,
          end: `${year}-${quarterObj[quarter][2]}-${lastDate}`,
          isToday: this.getIsToday(year, lastMonth)
        };

        const index = dateArr.findIndex(d => d.divider === divider);
        // 값이 없을때
        if (index === -1) {
          dateArr.push({
            divider,
            dividerPostfix: "",
            dateWidth: width,
            dividerWidth: width,
            children: [childObj]
          });
          continue;
        }

        dateArr[index].dateWidth += width;
        dateArr[index].dividerWidth += width;
        dateArr[index].children.push(childObj);
      }
    }
  }
};
