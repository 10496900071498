<template>
  <div
    class="cr_todo_gantt_header"
    :style="`width: ${totalDividerWidth}px;`"
    @mousedown.stop
  >
    <!-- 왼쪽 아이템 리스트 영역 -->
    <div class="cr_todo_item_area" :class="!showItemTitle ? 'cr_hide' : ''">
      <v-spacer />
      <v-btn
        icon
        outlined
        small
        class="mr-2 cr-tooltip"
        @click="$emit('update:showItemTitle', !showItemTitle)"
        @mouseover="e => setTooltip(e, '숨기기')"
        @mouseout="HIDE_TOOLTIP"
      >
        <v-icon>
          {{ showItemTitle ? "mdi-chevron-left" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>
    </div>

    <!-- 오른쪽 날짜 표시 -->
    <div class="cr_todo_date_area">
      <!-- divider -->
      <div class="cr_todo_date_wrapper">
        <div
          v-for="date in dateArr"
          :key="`${date.divider}_${date.dividerPostfix}`"
          :style="`width: ${date.dividerWidth}px;`"
          class="cr_todo_date_divider text-truncate"
        >
          <span class="font-weight-bold body-1">
            {{ date.divider }}
          </span>
          <span class="ml-2 grey--text text--darken-2">
            {{ date.dividerPostfix }}
          </span>
        </div>
      </div>

      <!-- display -->
      <div class="cr_todo_date_wrapper">
        <div
          v-for="date in dateArr"
          :key="`display_${date.divider}_${date.dividerPostfix}`"
          class="d-flex"
        >
          <div
            v-for="child in date.children"
            :key="child.key"
            class="cr_todo_date_display"
            :style="`width: ${child.width}px;`"
          >
            <div
              :class="
                `${child.isToday ? 'cr_todo_this_month' : ''} ${
                  child.isSunday ? 'cr_todo_gantt_sunday' : ''
                } ${child.isSaturday ? 'cr_todo_gantt_saturday' : ''}`
              "
            >
              {{ child.displayDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_header {
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 2;
  background: #fff;

  .cr_todo_item_area {
    position: sticky;
    left: 0px;
    z-index: 3;
    height: 64px;
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    display: flex;
    align-items: center;
    background: #fff;
    border-right: thin solid rgba(0, 0, 0, 0.12);
    border-bottom: thin solid rgba(0, 0, 0, 0.12);

    &.cr_hide {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
    }
  }

  .cr_todo_date_area {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cr_todo_date_wrapper {
      display: flex;
      width: 100%;
      height: 32px;
      border-bottom: thin solid rgba(0, 0, 0, 0.12);

      .cr_todo_date_divider {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }

      .cr_todo_date_display {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: thin solid rgba(0, 0, 0, 0.12);
        text-align: center;

        .cr_todo_this_month {
          margin: 0px 5px;
          background: var(--v-primary-base);
          width: 100%;
          height: 80%;
          border-radius: 10px;
          color: #fff;
          font-weight: 900;
        }
        .cr_todo_gantt_sunday {
          width: 100%;
          height: 100%;
          line-height: 2;
          background: #f6f7fb;
          color: red;
        }
        .cr_todo_gantt_saturday {
          width: 100%;
          height: 100%;
          line-height: 2;
          background: #f6f7fb;
          color: blue;
        }
      }
    }
  }
}
</style>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    dateArr: {
      type: Array,
      default: () => []
    },
    totalDividerWidth: {
      type: Number,
      default: 0
    },
    showItemTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    setTooltip(event, msg) {
      this.SET_TOOLTIP({ msg, position: "top", event });
    }
  }
};
</script>
