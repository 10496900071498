<template>
  <div class="cr_todo_gantt_item_row" :style="`width: ${totalDateWidth}px;`">
    <!-- 아이템 타이틀 -->
    <GanttItemTitle v-bind="$props" />
    <!-- 아이템 타임라인 -->
    <GanttItem v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_item_row {
  display: flex;
  height: 40px;
  &:hover::before,
  &:hover .cr_todo_gantt_item_title_wrapper::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    opacity: 0.1;
    width: 100%;
    height: 40px;
    left: 0px;
  }
}
</style>

<script>
import GanttItemTitle from "../item/GanttItemTitle.vue";
import GanttItem from "../item/GanttItem.vue";

export default {
  components: { GanttItemTitle, GanttItem },
  props: {
    dateArr: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    dragItemId: {
      type: String,
      default: ""
    },
    totalDateWidth: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 0
    },
    showItemTitle: {
      type: Boolean,
      default: false
    },
    selectedPeriod: {
      type: String,
      default: "Month"
    }
  },
  methods: {
    getDateKey(date) {
      return this.$parent.getDateKey(date);
    }
  }
};
</script>
