<template>
  <v-hover v-slot="{ hover }">
    <div
      class="cr_todo_gantt_item_title_wrapper"
      :class="!showItemTitle ? 'cr_hide' : ''"
    >
      <div v-if="showItemTitle" class="cr_todo_gantt_item_title text-truncate">
        {{ item.itemTitle }}
      </div>

      <div class="d-flex">
        <v-btn
          v-if="showItemTitle && hover"
          icon
          class="cr-tooltip"
          @click.stop="openDetail"
          @mousedown.stop
          @mouseover="
            event =>
              SET_TOOLTIP({ msg: '상세화면 보기', event, position: 'top' })
          "
          @mouseout="HIDE_TOOLTIP"
        >
          <v-icon size="16">mdi-arrow-top-right-bottom-left</v-icon>
        </v-btn>
        <v-btn
          v-if="showItemTitle && !item.start && !item.end"
          class="ml-1 cr-tooltip cr-menu-close-prevent"
          icon
          @click="openTimelineMenu"
          @mousedown.stop
          @mouseover="
            event =>
              SET_TOOLTIP({ msg: item.headerTitle, event, position: 'top' })
          "
          @mouseout="HIDE_TOOLTIP"
        >
          <v-icon>mdi-calendar-edit-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_item_title_wrapper {
  position: sticky;
  display: flex;
  left: 0px;
  width: 399px;
  height: 100%;
  line-height: 40px;
  padding: 0px 8px;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  &.cr_hide {
    width: 59px;
  }

  .cr_todo_gantt_item_title {
    min-width: 1px;
    padding-right: 4px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    showItemTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeState"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" })
  },
  methods: {
    ...mapMutations("todoTooltip", [
      "SET_TOOLTIP",
      "SET_MOVING_TOOLTIP",
      "HIDE_TOOLTIP"
    ]),
    ...mapActions("todoRoute", ["routeBoardAction"]),
    ...mapActions("todoMenu", ["setMenu"]),
    openDetail() {
      const { workspaceId, boardId, filterId, viewType } = this.routeState;
      const { id: itemId, groupId } = this.item;

      this.routeBoardAction({
        workspaceId,
        boardId,
        actionObj: JSON.stringify({ filterId, groupId, itemId, viewType })
      });
    },
    openTimelineMenu(e) {
      const { top, left, height, width } = e.target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = 106;
      const naviWidth = rootWidth - listWidth;
      const { id: itemId, parentId, boardId, groupId } = this.item;

      this.setMenu({
        type: "timeline",
        itemId,
        parentId,
        groupId,
        boardId,
        headerValue: this.item.headerValue,
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height,
        autoClose: true
      });
    }
  }
};
</script>
