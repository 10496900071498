<template>
  <div
    class="cr-todo-gantt-item-label ml-3 grey--text text--light-2 text-body-2 align-center"
    :style="style"
  >
    <div class="cr_todo_gantt_item_header">{{ item.headerTitle }}</div>
    <div class="ml-1">{{ item.itemTitle }}</div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-gantt-item-label {
  display: flex;

  .cr_todo_gantt_item_header {
    background: rgba(0, 0, 0, 0.05);
    color: #333;
    padding: 2px 5px;
    font-size: 12px;
  }
}
</style>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    dateArr: {
      type: Array,
      default: () => []
    },
    left: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("update:labelWidth", this.$el.offsetWidth);
    });
  },
  computed: {
    style() {
      return `position: absolute; left: ${this.left}px; opacity: ${
        this.left > 0 ? 1 : 0
      };`;
    }
  },
  watch: {
    item(n, p) {
      if (n?.itemTitle !== p?.itemTitle) {
        this.$nextTick(() => {
          this.$emit("update:labelWidth", this.$el.offsetWidth);
        });
      }
    },
    dateArr() {
      this.$nextTick(() => {
        this.$emit("update:labelWidth", this.$el.offsetWidth);
      });
    }
  }
};
</script>
