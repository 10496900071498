<template>
  <div
    class="cr_todo_gantt_group_wrapper"
    :style="`width: ${totalDateWidth}px;`"
  >
    <div
      v-for="group in groupList"
      :key="`title_group_${group.id}`"
      class="cr_todo_gantt_group"
    >
      <!-- 그룹 타이틀 -->
      <div
        class="cr_todo_gantt_group_title"
        :class="!showItemTitle ? 'cr_hide' : ''"
      >
        <div
          class="cr_todo_gantt_group_color"
          :style="`background: ${group.color};`"
        />
        <h3 v-if="showItemTitle">{{ group.title }}</h3>
      </div>

      <GanttItemRow
        v-bind="$props"
        v-for="(item, idx) in itemObj[group.id]"
        :key="`title_item_${item.id}_${idx}`"
        :item="item"
        :group="group"
      />
    </div>
    <div
      v-if="groupList.length == 0"
      :style="`height: ${totalHeight}px`"
      :class="!showItemTitle ? 'cr_hide' : ''"
      class="cr_todo_gantt_group_empty"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_group_wrapper {
  position: absolute;
  top: 64px;
  left: 0px;
  .cr_todo_gantt_group {
    .cr_todo_gantt_group_title {
      position: sticky;
      display: flex;
      align-items: center;
      left: 0px;
      width: 399px;
      height: 40px;
      line-height: 40px;
      padding: 0px 8px;
      background: #fff;

      .cr_todo_gantt_group_color {
        height: 20px;
        width: 20px;
        border-radius: 7px;
        margin-right: 8px;
        margin-bottom: 2px;
      }

      &.cr_hide {
        width: 59px;
        .cr_todo_gantt_group_color {
          margin-right: 0px;
          margin-left: 19px;
        }
      }
    }
  }

  .cr_todo_gantt_group_empty {
    position: sticky;
    left: 0px;
    background: #fff;
    width: 400px;
    border-right: thin solid rgba(0, 0, 0, 0.12);
    &.cr_hide {
      width: 60px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import GanttItemRow from "./item/GanttItemRow.vue";

export default {
  components: {
    GanttItemRow
  },
  props: {
    dateArr: {
      type: Array,
      default: () => []
    },
    groupList: {
      type: Array,
      default: () => []
    },
    itemObj: {
      type: Object,
      default: () => ({})
    },
    dragItemId: {
      type: String,
      default: ""
    },
    totalDateWidth: {
      type: Number,
      default: 0
    },
    totalHeight: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 0
    },
    showItemTitle: {
      type: Boolean,
      default: false
    },
    selectedPeriod: {
      type: String,
      default: "month"
    }
  },
  computed: {
    ...mapGetters("todoHeader", ["header"])
  },
  methods: {
    // return 값을 못받아서 이렇게 처리
    getDateKey(date) {
      return this.$parent.getDateKey(date);
    }
  }
};
</script>
