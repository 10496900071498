<template>
  <div class="cr_todo_gantt_item_content">
    <v-hover v-if="showItem" v-slot="{ hover }">
      <div
        :id="`item_${item.id}_${item.headerValue}`"
        :data-id="item.id"
        :data-gid="group.id"
        :data-start="item.start"
        :data-end="item.end"
        :data-left="itemLeft"
        :data-width="itemWidth"
        :data-position="showItem"
        :data-fullwidth="itemFullWidth"
        :data-maxwidth="itemMaxWidth"
        class="cr_todo_gantt_date_range"
        :class="hover || draggedItem ? 'cr-dragged' : ''"
        :style="
          `left: ${itemLeft}px; width: ${itemWidth}px; background: ${group.color};`
        "
        @mouseover="mouseover"
        @mouseout="mouseout"
      >
        <!-- 좌우 조절 영역 -->
        <div
          v-if="hover || draggedItem"
          class="cr_todo_gantt_date_range_left"
          :style="`background: ${group.color};`"
        />
        <div
          v-if="hover || draggedItem"
          class="cr_todo_gantt_date_range_right"
          :style="`background: ${group.color};`"
        />
      </div>
    </v-hover>
    <!-- 날짜 오른쪽 아이템 타이틀 -->
    <GanttItemLabel
      v-if="showItem"
      v-show="showLabel"
      :item="item"
      :left="labelLeft"
      :dateArr="dateArr"
      :labelWidth.sync="labelWidth"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr_todo_gantt_item_content {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .cr_todo_gantt_date_range {
    position: absolute;
    height: 50%;
    border-radius: 7px;
    cursor: grab;
    z-index: 1;
    &.cr-dragged {
      border-radius: 0px;
      &::before {
        content: "";
        position: absolute;
        background-color: #fff;
        opacity: 0.4;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }
    }

    .cr_todo_gantt_date_range_left {
      position: absolute;
      left: -10px;
      width: 10px;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      cursor: e-resize;
    }
    .cr_todo_gantt_date_range_right {
      position: absolute;
      right: -10px;
      width: 10px;
      height: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: e-resize;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import GanttItemLabel from "./GanttItemLabel.vue";

export default {
  components: { GanttItemLabel },
  props: {
    dateArr: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    dragItemId: {
      type: String,
      default: ""
    },
    totalDateWidth: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 0
    },
    showItemTitle: {
      type: Boolean,
      default: false
    },
    selectedPeriod: {
      type: String,
      default: "Month"
    }
  },
  data() {
    return {
      labelWidth: 0
    };
  },
  computed: {
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    // dateArr의 처음과 마지막 날짜
    startAndEndDate() {
      if (this.dateArr.length === 0) return [];

      const [firstObj] = this.dateArr.at(0)?.children || [];
      const lastObj = this.dateArr.at(-1)?.children?.at(-1);
      if (!firstObj || !lastObj) return [];

      return [
        new Date(`${firstObj.start} 0:0:0`),
        new Date(`${lastObj.end} 0:0:0`)
      ];
    },
    // 타임라인 컬럼의 처음과 마지막 날짜
    startAndEndItem() {
      const { start, end } = this.item;
      if (!start || !end) return [];

      return [new Date(`${start} 0:0:0`), new Date(`${end} 0:0:0`)];
    },
    showItem() {
      const [start_d, end_d] = this.startAndEndDate;
      if (!start_d || !end_d) return false;

      const [start_i, end_i] = this.startAndEndItem;
      if (!start_i || !end_i) return false;

      if (start_i <= start_d && end_i > start_d) return "first";
      if (start_i >= start_d && start_i <= end_d) return "default";
      return "";
    },
    itemLeft() {
      if (!this.showItem) return undefined;
      if (this.showItem === "first") return 0;

      const [start] = this.startAndEndItem;
      const year = start.getFullYear();
      const month = start.getMonth() + 1;
      const date = start.getDate();
      const day = start.getDay();
      const dateKey = this.$parent.getDateKey({ year, month, date });
      const dateEl = document.getElementById(dateKey);

      if (!dateEl) return undefined;
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth, scrollLeft } = document.getElementById("todoList");
      const { left } = dateEl.getBoundingClientRect();
      const naviWidth = rootWidth - offsetWidth;
      // 399는 itemTitle 공간
      const itemTitleWidth = this.showItemTitle ? 399 : 60;
      const defaultLeft =
        scrollLeft + left - naviWidth - itemTitleWidth + this.sidePanelWidth;
      const { quarterLastDate } = this.item;
      switch (this.selectedPeriod) {
        case "Week":
          return defaultLeft + ((day || 7) - 1) * this.distance;
        case "Month":
          return defaultLeft + (date - 1) * this.distance;
        case "Quarter":
          return defaultLeft + quarterLastDate * this.distance;
      }

      return defaultLeft;
    },
    itemMaxWidth() {
      if (!this.showItem) return 0;

      const [start_i] = this.startAndEndItem;
      const [start_d, end_d] = this.startAndEndDate;
      const isFirst = this.showItem === "first";
      const startTime = isFirst ? start_d.getTime() : start_i.getTime();
      const diffDate = this.getDiffDate(startTime, end_d.getTime());

      return (diffDate + 1) * this.distance;
    },
    itemFullWidth() {
      if (!this.showItem) return 0;

      const [start, end] = this.startAndEndItem;
      const diffDate = this.getDiffDate(start.getTime(), end.getTime());
      return (diffDate + 1) * this.distance;
    },
    itemWidth() {
      if (!this.showItem || !this.dateArr.length) return 0;
      // 끝나는 날짜가

      const [start_i, end_i] = this.startAndEndItem;
      const [start_d, end_d] = this.startAndEndDate;
      const isFirst = this.showItem === "first";
      const isEnd = end_i.getTime() >= end_d.getTime();
      const startTime = isFirst ? start_d.getTime() : start_i.getTime();
      const endTime = isEnd ? end_d.getTime() : end_i.getTime();
      const diffDate = this.getDiffDate(startTime, endTime);

      return (diffDate + 1) * this.distance;
    },
    showLabel() {
      if (this.labelWidth === 0) return true;
      return this.itemWidth + this.labelWidth < this.itemMaxWidth;
    },
    labelLeft() {
      if (this.labelWidth === 0) return -1000;
      return this.itemLeft + this.itemWidth;
    },
    draggedItem() {
      return this.dragItemId == `item_${this.item.id}_${this.item.headerValue}`;
    }
  },
  methods: {
    ...mapMutations("todoTooltip", [
      "SET_TOOLTIP",
      "SET_MOVING_TOOLTIP",
      "HIDE_TOOLTIP"
    ]),
    mouseover(event) {
      if (this.dragItemId) return;

      this.SET_MOVING_TOOLTIP({
        event,
        msg: `${this.item.start} - ${this.item.end}`,
        position: "top"
      });
    },
    mouseout() {
      if (this.dragItemId) return;
      this.HIDE_TOOLTIP();
    },
    getDiffDate(startTime, endTime) {
      return Math.abs((startTime - endTime) / 86400000);
    }
  }
};
</script>
