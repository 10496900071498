<template>
  <div class="cr-todo-gantt-toolbar-wrapper">
    <!-- 오늘 날짜로 이동 -->
    <v-btn
      icon
      class="cr-tooltip"
      @click="() => $parent.initDate()"
      @mouseover="e => setTooltip(e, '오늘')"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon size="22">mdi-calendar-today-outline</v-icon>
    </v-btn>
    <!-- 자동맞춤 -->
    <v-btn class="px-2" title text @click="autoSetting">자동맞춤</v-btn>
    <!-- 줌 아웃 -->
    <v-btn
      icon
      small
      class="cr-tooltip"
      @click="$emit('update:distance', -2)"
      @mouseover="e => setTooltip(e, '줌 아웃')"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon size="18">mdi-minus</v-icon>
    </v-btn>
    <!-- 줌인 -->
    <v-btn
      icon
      small
      class="cr-tooltip"
      @click="$emit('update:distance', 2)"
      @mouseover="e => setTooltip(e, '줌 인')"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon size="18">mdi-plus</v-icon>
    </v-btn>

    <v-spacer />
    <!-- 기간 단위 선택 -->
    <v-select
      ref="selectRef"
      dense
      outlined
      hide-details
      :items="periodItems"
      :menu-props="{ 'offset-y': true, closeOnClick: true }"
      v-model="selectedPeriodItem"
      @click:append.stop="$refs.selectRef.activateMenu()"
    ></v-select>
    <v-btn
      icon
      class="ml-3 cr-tooltip"
      @click="excelDownload = true"
      @mouseover="e => setTooltip(e, '엑셀로 다운로드')"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon>mdi-content-save-outline</v-icon>
    </v-btn>
    <GanttExcelDownload
      v-if="excelDownload"
      :itemObj="itemObj"
      :groupList="groupList"
      :excelDownload.sync="excelDownload"
    />
    <!-- 설정 -->
    <v-btn
      icon
      small
      class="mr-5 ml-3 cr-tooltip cr-menu-close-prevent"
      @click="openSettingModal"
      @mouseover="e => setTooltip(e, '설정')"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon size="26">mdi-cog-outline</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-gantt-toolbar-wrapper::v-deep {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-top: thin solid rgba(0, 0, 0, 0.1);
  border-bottom: thin solid rgba(0, 0, 0, 0.1);

  .v-input.v-select {
    max-width: 130px;
    max-height: 35px;
    margin-bottom: 1px;
    .v-input__slot {
      height: 35px;
      min-height: 35px;

      fieldset {
        top: -3px;
      }

      .v-input__append-inner {
        margin-top: 7px;
      }
    }
  }
}
</style>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: { GanttExcelDownload: () => import("./GanttExcelDownload.vue") },
  props: {
    itemObj: {
      type: Object,
      default: () => ({})
    },
    groupList: {
      type: Array,
      default: () => []
    },
    dateArr: {
      type: Array,
      default: () => []
    },
    distance: {
      type: Number,
      default: 0
    },
    selectedPeriod: {
      type: String,
      default: "month"
    }
  },
  data() {
    return {
      excelDownload: false,
      periodItems: [
        { text: "일", value: "Date" },
        { text: "주", value: "Week" },
        { text: "월", value: "Month" },
        { text: "분기", value: "Quarter" }
      ]
    };
  },
  computed: {
    ...mapGetters("todoModal", { modalType: "type", modalParams: "params" }),
    selectedPeriodItem: {
      get() {
        return this.selectedPeriod;
      },
      set(period) {
        this.$emit("update:selectedPeriod", period);
      }
    },
    getDateRange() {
      let firstDate = null;
      let lastDate = null;
      Object.keys(this.itemObj).forEach(groupId => {
        this.itemObj[groupId].forEach(({ start, end }) => {
          const startDate = new Date(start);
          const endDate = new Date(end);
          if (!firstDate) {
            firstDate = startDate;
            lastDate = endDate;
            return;
          }

          if (firstDate.getTime() > startDate.getTime()) {
            firstDate = startDate;
          }

          if (lastDate.getTime() < endDate.getTime()) {
            lastDate = endDate;
          }
        });
      });

      return { firstDate, lastDate };
    }
  },
  methods: {
    ...mapMutations("todoModal", ["SET_MODAL", "CLOSE_MODAL"]),
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    setTooltip(event, msg) {
      this.SET_TOOLTIP({ msg, position: "top", event });
    },
    autoSetting() {
      const { $parent } = this;
      const { firstDate, lastDate } = this.getDateRange;

      if (!firstDate || !lastDate) return;

      let period = "Week";
      // 연도 비교
      if (firstDate.getFullYear() !== lastDate.getFullYear()) {
        period = "Quarter";
      }
      // 월 비교
      if (firstDate.getMonth() !== lastDate.getMonth()) {
        period = "Month";
      }

      if (this.selectedPeriod !== period) {
        this.$emit("update:selectedPeriod", period);
        return;
      }

      $parent.initDate($parent.firstItemStartDate);
    },
    openSettingModal() {
      const type = "gantt-setting";
      if (this.modalType === type) {
        this.CLOSE_MODAL();
        return;
      }

      setTimeout(() => {
        this.SET_MODAL({ type, params: { top: 105, right: 20 } });
      }, 0);
    }
  }
};
</script>
